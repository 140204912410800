import React from "react";
import { Global, css } from "@emotion/core"
import { color } from "../components/defaults/styles/theme";
import ExpoLogo from "../components/defaults/assets/logos/expo8_pri_wht_rgb.svg"
import FourOFourLogo from "../components/defaults/assets/svg/FourOFour.svg"
import Link from "../components/link"
import navigation_data from "../data/navigation_data";
import mq from "../components/defaults/styles/mediaquerys";
import "typeface-inter"
import SEO from "../components/SEO/seo"

const seo = {
        frontmatter: {
            title: '404 – not everything is infinite'
        }
    }

const styleLink = css`
  font-size: 0.75em;
  color: white;
  text-decoration: none;
  ${mq[1]} {
      font-size: .875rem;
  }
`

const Navigation = ({data}) => {
    const nav = data.footer[2].items;
    const nav_selection = nav.slice(0, 3);
    return (
      <div css={css`
          margin-top: .75rem;
          opacity: .4;
          ${mq[1]} {
              margin-top: 0;
          }
      `}>
        {nav_selection.map((item, index) => (
            <Link key={index}
                  to={item.url}
                  css={[styleLink, css`
                    margin-right: 1rem;
                  `]}>
                {item.name}    
            </Link>
        ))}
      </div>
    )
}

const FourOFour = () => {
    return (
      <>
        <Global
          styles={css`
            body,
            html,
            #___gatsby,
            #gatsby-focus-wrapper {
              height: 100%;
              background: ${color.main_dark};
            }
          `}
        />
        <SEO {...seo} />
        <section
          css={css`
            padding: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            overflow: hidden;
            ${mq[1]} {
              padding: 50px;
            }
            > svg {
              fill: white;
              width: 90%;
              position: absolute;
              left: 0;
              bottom: 0;
              opacity: 0.2;
              z-index: 0;
            }
          `}
        >
          <FourOFourLogo />
          <div
            css={css`
              svg {
                fill: white;
                width: 6rem;
              }
            `}
          >
            <Link to="/">
              <ExpoLogo />
            </Link>
          </div>
          <div
            css={css`
              z-index: 1;a
          `}
          >
            <div
              css={css`
                color: white;
              `}
            >
              <h1
                css={css`
                  margin-bottom: .8rem;
                  font-size: 2.5rem;
                  max-width: 550px;
                  line-height: 1.3em;
                  ${mq[1]} {
                    font-size: 3.125rem;
                  }
                  ${mq[2]} {
                    font-size: 4.125rem;
                    max-width: 750px;
                  }
                `}
              >
                The sum of innovation is infinite.
              </h1>
              <p
                css={css`
                  font-size: 1.2rem;
                  font-weight: 400;
                  ${mq[1]} {
                    font-size: 1.875rem;
                  }
                  ${mq[2]} {
                    font-size: 2.275rem;
                  }
                `}
              >
                but this website isn‘t
              </p>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  ${mq[1]} {
                    flex-direction: row;
                    justify-content: space-between;
                  }
                `}
              >
                <Link
                  to="/"
                  css={[
                    styleLink,
                    css`
                      display: flex;
                      align-items: center;
                    `,
                  ]}
                >
                  <svg
                    viewBox="0 0 43 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
                      margin-right: 0.7em;
                      height: 0.75em;
                    `}
                  >
                    <path
                      d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM43 7L1 7V9L43 9V7Z"
                      fill="white"
                    />
                  </svg>
                  back to homepage
                </Link>
                <Navigation data={navigation_data} />
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default FourOFour;