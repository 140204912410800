export const size = {
    maxWidth: '77rem',
}

export const color = {
         main_dark: "#121212",
         main_light: "#fafafa",
         accent_light: "#eaeaea",

         red: "#de166b",
         blue: "#4645b9",
         orange: "#ef7800",

         cta_green: "#0cb78b",
         rec: "#ff2d3f",
       }